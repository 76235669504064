* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
p{
  margin-bottom: 0;
}
nav {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
}
nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 0;
  gap: 30px;
}
nav ul li {
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  /* border-bottom: 3px solid #ffffff;
  background-color: red; */
  padding: 8px 15px;
  border-radius: 30px;
  text-decoration: none;

}
nav button {
  width: 104px;
  height: 48px;
  background: #fb7712;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}
.lg-btn{
  width: 100%;
  height: 48px;
  background: #fb7712;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}
.seeall-btn{
  background: #fb7712;
  border: none;
  color: white ;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  /* height: 48px; */
  padding: 10px 25px;

}
.lg-btn2{
  width: 100%;
  height: 48px;
  background: #fff;
  border: none;
  color: #fb7712;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}
.banner {
  /* background-image: url(./Assets/31098.jpg); */
  padding: 200px 50px;
  background-position: center;
  background-size: cover;
  z-index: 0 !important;
  position: relative;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));


}
.banner2{
  background-image: url(./Assets/31098.jpg);

}
.banner h2 {
  font-family: "Playfair Display", serif;
  font-size: 70px;
  font-weight: 600;
  color: white;
}
.banner p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: white;
}
.banner button {
  background-color: #fb7712;
  height: 62px;
  width: 167px;
  border-radius: 12px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.explore {
  width: 100%;
  position: relative;
  background-color: #fef7f4;
  border-radius: 50px 50px 0 0;
  padding: 50px;
  margin-top: -100px;
  z-index: 10;
}
.explore-heading {
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  height: 80px;
  margin-top: -100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  /* position: absolute;
  z-index: 10; */
}
.explore-heading button{
  background-color: #FB7712;
  color: white;
  width: 136.95px;
height: 60px;
border-radius: 12px;
border: none;
font-family: "Montserrat", sans-serif;
font-size: 15px;
font-weight: 500;
}
.Explore-card {
  height: 131px;
  width: 131px;
  background: #fb77120f;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ExploreTripolo{
  font-family: "Playfair Display", serif;
  font-size: 29px;
  font-weight: 700;
}
.Explore-card p {
}
.Explore-card-div p {
  font-family: "Playfair Display", serif;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
}
.Explore-card-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}
.Explore-card img{
  height: 60px;
  /* width: 100%; */
  /* border-radius: 50%; */
}
.Explore-cards {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  justify-content: start;
  gap: 50px;
  overflow-x: auto;
}
.collection {
  padding: 50px;
}
.collection-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.collection-heading h2 {
  font-family: "Playfair Display", serif;
  font-size: 29px;
  font-weight: 700;
}
.collection-heading p {
  font-family: "Playfair Display", serif;
  font-size: 15px;
  font-weight: 400;
}
.collection-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.collection-grid-left {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.collection-grid-right {
  width: 50%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.collection-grid-left1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
}
.collection-grid-left1 {
  /* width: 50%; */
}
.collection-grid-left1 img {
  border-radius: 12px;
  width: 100%;
}
.grab-offer {
  background-color: #fdf7f4;
  background-image: url(./Assets/image121.png);
  background-size: cover;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.grab-offer-left {
  width: 50%;
  padding: 50px;
}
.grab-offer-right {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
}
.grab-offer-right img{
  width: 100%;
}
.grab-offer-left h2 {
  font-family: "Playfair Display", serif;

  font-size: 40px;
  font-weight: 700;
  margin-bottom: 15px;
}
.grab-offer-left h2 span {
  color: #fb7712;
}
.grab-offer-left p {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.grab-offer-left button {
  width: 167.86px;
  height: 62px;
  background-color: #fb7712;
  border: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border-radius: 12px;
}

.trending {
  padding: 50px;
}
.trending-card {
  width: 270px;
    height: 300px;
  background-color: #000000;
  border-radius: 20px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  
}
.trending-card010{
  width: 270px;
  height: 300px;
}
.trending-card img {
  width: 100%;
  /* height: 100%; */
  z-index: 0;
  border-radius: 20px 20px 0 0;
}
.trending-text {
  /* position: absolute; */
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 10px 20px;
  /* height: 269px; */
  margin-top: -30px;
  border-radius: 20px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.1);

  z-index: 10;
}
.trending-text h3 {
  font-family: "Playfair Display", serif;

  font-size: 20px;
  font-weight: 600;
  margin-bottom: -5px;
}
.trending-text-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.trending-text-line p {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #737373;
  margin-top: 12px;
  margin-bottom: 0;
}
.trending-text-line2{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  /* margin-top: 5px; */
  margin-bottom: 5px;
  gap: 10px;
}
.trending-text-line2 img{
  width: 20px;
  
}
.trending-text-line2 p{
  margin-bottom: 0;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #737373;
}
.Ameneties-div button{
  background: none;
  border: none;
  font-family: "Playfair Display", serif;
  font-size: 15px;
  font-weight: 400;
  margin-top: -10px;
  color: #FB7712;

}
.trending-text-line p i {
  color: #fb7712;
}
.stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px !important;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #ffc700 !important;
}
.trending-text-line h2 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
margin-top: 5px;
  font-weight: 600;
}
.trending-text-line h2 span {
  font-size: 12px;
  font-weight: 500;
  color: #737373;
}
.trending-text-line button {
  height: 30px;
  width: 80px;
  border-radius: 6px;
  background: #fb7712;
  color: white;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  border: none;
  font-weight: 700;
}
.trending-cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  /* height: 350px; */
  padding-bottom: 35px;
}
.Whychoose {
  padding: 50px;
  display: flex;
  flex-direction: row;
  background-color: #fef7f4;
}
.Whychoose-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Whychoose-left h2 {
  font-family: "Playfair Display", serif;

  font-size: 29.88px;
  font-weight: 700;
}
.Whychoose-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Whychoose-right img {
  width: 70%;
}
.Whychoose-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.Whychoose-text h4 {
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 700;
}

.Whychoose-text p {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  margin-top: 15px;
}
.count {
  padding: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 30px;
}
.count-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.count-card h2 {
  font-size: 38px;
  font-family: "Montserrat", sans-serif;

  font-weight: 700;
}
.count-card p {
  font-size: 15px;
  font-family: "Playfair Display", serif;

  font-weight: 400;
}
.ourfetured {
  padding: 50px;
  padding-top: 50px;
  background-color: #fef7f4;
  border-radius: 80px 80px 0 0;
}
.testimonials {
  padding: 50px;
  background-image: url(./Assets//test-back.png);
}
.testimonials-slid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.testimonials-slid h4 {
  font-size: 30px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

.testimonials-slid h6 {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;

  font-weight: 500;
  color: #fb7712;
}
.testimonials-slid h5 {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.testimonials-slid h3 {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.testimonials-slid p {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

.trending-cards2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  flex-wrap: wrap;
  /* padding: 50px; */
}
.detail-left {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 20px;

}
.detail-left img{
  width: 90%;



}
.Collection-detail {
  padding: 15px;
  box-shadow: 0px 10px 40px 0px #0000000d;
  position: sticky;
  top: 0;
  border-radius: 20px;
}
.Collection-detail h3 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 600;
}
.Collection-detail h2{
  font-family: "Montserrat", sans-serif;

  font-size: 22px;
  font-weight: 600;
}
.detail{
  display: flex;
  flex-direction: row;
}
.detail-left {
  width: 40%;
}
.detail-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 50px;
}
.main-img img{
  width: 100%;

}
.Collection-detail h3 {
  font-family: "Playfair Display", serif;
  font-size: 30px;
  font-weight: 600;
}
.Collection-detail p {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #737373;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 0;

}
.Collection-detail p i{
  color: #FB7712;
}
.size{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6E6E6;
}
.size p{
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: black;
}
.size h6{
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.Collection-detail button{
  width: 100%;
  height: 56px;
  border-radius: 8px;
  border: 1px solid #FB7712;
  margin-top: 15px;
  background: none;
  color: #FB7712;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.whatsapp-btn{
  background-color: #FB7712 !important;
  color: white !important;
}
.side-imgs{
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  gap: 20px;
}
.side-imgs img{
  width: 20%;
  /* height: 153px; */
}
.explore2{
  background-color: white;
}
.description{

}
.tab-container{
  padding: 50px;
  padding-top: 0px;
}
.tabs{
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  gap: 20px;
  overflow-x: auto;
  position: sticky;
  top: 0;
  background-color: white !important;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
}
.tabs button{
  background: none;
  border: none;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 400;
  background-color: #e5e5e5 !important;
}
.description h2{
  font-family: "Playfair Display", serif;
  font-size: 29px;
  font-weight: 500;
}
.description p{
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}
.description ul li{
  font-family: "Montserrat", sans-serif;
  color: #000000;
  font-size: 12px;
  font-weight: 500;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 10px;
  margin-bottom: 10px;

}
.description ul li img{
  height: 14px;
  margin-top: 3px;
}
.description ul{
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 15px;

}
.Spaces-card{
  Width:250px;
Height:180px;
background-image: url(./Assets/image\ 11.png);
background-position: center;
display: flex;
align-items: end;
color: white;
margin-top: 12px;
border-radius: 20px;

}
.Spaces-card h3{
  font-family: "Playfair Display", serif;
  font-size: 15px;
  font-weight: 500;
}
.space-over{
  height: 100%;
  width: 100%;
  background-color: #000000;
padding: 5px 10px;
border-radius: 20px;
background: linear-gradient(to top, rgba(0, 0, 0, 0.407), rgba(0, 0, 0, 0));
display: flex;
align-items: end;

}
.Spaces{
  display: flex;
  flex-direction: row !important;
  justify-content: start;
  gap: 20px;
  overflow-x: auto;
}
.Spaces ul{
  padding-left: 0;
  Width:200px;

}
.Spaces ul li{
  font-family: "Montserrat", sans-serif;
 
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  
}
.Ameneties h3{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 400;
}
.Ameneties img{
  height: 20px;
}
.Ameneties-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 40px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.map{
  height: 200px;
  width: 100%;
  margin-top: 15px;
}
.map iframe{
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
}
.footer{
  background-color: #000000;
  padding: 50px;
  color: white;
}
.footer ul{
  color: white;
  font-family: "Montserrat", sans-serif;
 
  font-size: 16px;
  font-weight: 500;
}
.footer ul li{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  color: #8E8E8E;
}
.footer ul li i{
  color: #FB7712;
}
.footer-links{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer-links ul{
  padding-left: 0;

}
.subs{
  width: 278px;
height: 58px;

border-radius: 8px ;
border: 1px solid #737373;
margin-top: 15px;
display: flex;
justify-content: center;
align-items: center;
gap: 20px;


}
.subs input{
  width: 208px;
  height: 100%;
  background: none;
  border: none;

}
.subs button{
  width: 278px;
  height: 58px;
  background-color: #FB7712;
  border: none;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}
.contacts{
  border: 1px solid #313131;
  border-radius: 32px;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}
.contacts-div{
  width: 30%;
  display: flex;
  align-items: center;
  gap: 15px;

}
.contacts-div p{
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.contacts-div h3{
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 500;
}
.reserved{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Followus{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}
.Followus p{
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.srcicon{
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
.srcicon input{
  border: none;
  background: none;
  font-size: 13px;
  font-weight: 400;
  color: #717171;

}
.srcicon h6{
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.srcicon p{
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #717171;
  margin-bottom: 0;

}


.toggleDiv {
  
  /* width: 100%; */
  height: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color:rgba(19, 11, 70, 0.25);
  border: 1px solid #787878;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  text-align: center;
margin-top: 150px;
padding-top: 50px;
z-index: 100;

}
.toggleDiv ul{
  padding-left: 0px;
}
.toggleDiv ul li{
  font-family: "Manrope", sans-serif;
  /* color: rgb(255, 255, 255); */

  font-weight: 700;
  font-size: 16px;
  list-style: none;
  margin-bottom: 25px;
}
.toggleDiv-ops{
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
z-index: 100;
  top: 90px;
}
/* Navbar.css */

/* Keyframes for the slide-in animation */
@keyframes slideUp {
from {
  transform: translateY(100%);
  opacity: 0;
}
to {
  transform: translateY(0);
  opacity: 1;
}
}

/* Style for the toggle div */
.toggleDiv {

animation: slideUp 0.5s ease-out; /* Apply the slide-up animation */
z-index: 1000; /* Ensure it's on top */
}

.toggleDiv {
padding: 20px; /* Padding for content */
}
.bi-list{
  display: none;
}
.subs-div i{
font-size: 20px;

}
@media(max-width:1080px){
  nav {
    padding-left: 30px;
    padding-right: 30px;
  }
nav ul {
  gap: 20px;

}
nav ul li {
  font-weight: 400;

}


}
.profile-section {
  position: relative;
  display: inline-block;
}

.profile-icon {
  font-size: 24px;
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.profile-dropdown p {
  margin: 0;
  font-size: 16px;
}

.profile-dropdown button {
  margin-top: 10px;
  background-color: #FB7712;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

@media(max-width:960px){
  nav li
  {
    display: none;
  }
  .bi-list{
    display: block !important;
  }
  /* .profile-section{
    display: none;
  } */
}
@media(max-width:768px){
  .banner {
  padding: 150px 30px;
  padding-top: 50px;
    /* height: 70vh; */
  }
.banner h2 {
  font-size: 25px;

}
.banner button{
  height: 40px;
  width: 100px;
  font-size: 12px;

}
.ExploreTripolo{
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 700;
}
}
@media(max-width:890px){
  .explore-heading {
    padding-left: 20px;
    padding-right: 20px;
  }
  .explore-heading button{
    width: 100px;
    height: 50px;
  }
}
@media(max-width:868px){
  .grab-offer {
    flex-direction: column;
  }
  .grab-offer-left,.grab-offer-right {
    width: 100%;
  }
  .Whychoose {
    flex-direction: column;
    gap: 50px;
  }
  .Whychoose-left {
    width: 100%;
  }
  .Whychoose-right {
    width: 100%;
  }
  .Whychoose-right img {
    width: 100%;
  }
}
@media(max-width:768px){
  .explore-heading {
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  flex-direction: column;
  gap: 15px;
    align-items: start;
  }
  .explore-heading button{
    width: 100%;
  }
  .Explore-cards{
    margin-top: -30px;
    display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;

  }
  .Explore-card-div{

  }
.explore {
  padding: 20px;
}
.Explore-cards{
  justify-content: space-evenly;
}
.Explore-card {
  height: 80px;
  width: 80px;
}
.Explore-card img{
  height: 25px;
}
.collection-grid{
  flex-direction: column;
}
.collection-grid-left , .collection-grid-right{
  width: 100%;
}
.grab-offer-left {

  padding: 30px;
}
.grab-offer-left h2 {
  font-size: 30px;
}
.collection{
  padding: 20px;
  padding-top: 0px !important;
}
.trending {
  padding: 30px;
  padding-bottom: 20px;
}
.trending-card{
  width: 240px;
  /* height: 350px; */
  /* background-color: red; */
}
.trending-cards{
  /* height: 300px; */
  /* padding-bottom: 100px; */
}
.trending-text h3 {
  font-size: 15px;

}
.trending-text-line p {
  font-size: 12px;
  
}
.trending-text-line h2 {
  font-size: 15px;

}
.trending-text-line h2 span {
  font-size: 12px;
}

.trending-cards{
/* padding-bottom: 20px; */
gap: 20px;
}
.Whychoose {
  padding: 30px;
  padding-top: 0px;
}
.count-card h2 {
  font-size: 30px;
}
.count {
  padding: 30px;

}
.ourfetured{
  padding: 30px;
  padding-bottom: 15px;

}
.footer-links{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}
.footer{
  padding: 30px;
}
.contacts{
  flex-direction: column;
  gap: 30px;
}
.contacts-div{
  width: 100%;
}
.collection-heading h2 {
  font-size: 18px;

}
.collection-heading p {
  font-size: 12px;

}
.Ameneties{
  /* width: 28%; */
}
.Ameneties h3{
  font-size: 15px;
}
.Ameneties img{
  height: 18px;
}
.Ameneties-div{
  gap: 30px;
  justify-content: start
}
}

@media(max-width:1250px){
  .contacts-div h3{

  font-size: 18px;
  }
  .contacts-div p{
  font-size: 12px;

  }
  .trending-cards2 {
    gap: 20px;
  justify-content: center;
  padding: 35px;

  }
.Collection-detail h3 {
  font-family: "Playfair Display", serif;
  font-size: 22px;
  font-weight: 600;
}
.size{
  padding-bottom: 10px;

}
.size p{
  font-size: 15px;

}
.size h6{
  font-size: 15px;
}
.detail-left {
  padding: 20px;
}
.detail-left {
  width: 50%;
}
.detail-right {
  width: 50%;
}
.tabs button{

  background: none;
  border: none;
  font-family: "Playfair Display", serif;
  font-size: 14px;
  font-weight: 400;
}
.Spaces{
  gap: 20px;

}
.tab-btn {
    width: 100px !important;
}

.Spaces-card{
  Width:200px;
Height:150px;
background-size: cover;
border-radius: 20px;
}

}
@media(max-width:900px){
  .detail{
    flex-direction: column;
  }
  .detail-left {
    width: 100%;
  }
  .detail-right {
    width: 100%;
    
  }
  .detail-left{
    padding: 0;
  }
  .tabs{
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 8px;
    overflow-x: auto;
  padding-top: 10px;
  padding-bottom: 15px;

  }
  .tab-container{
    padding: 20px;
  }
}
@media(max-width:600px){

.reserved{
  flex-direction: column;
  text-align: center;
  gap: 10px;

}
.reserved p{
  font-size: 15px;

}
.Followus p{
  font-size: 12px;

}
.subs{
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}
.subs-div{
  width: 100%;

}
.subs-div button{
  width: 100%;

}
}
.none{
  color: white;
  text-decoration: none;
}
.none1{
  text-decoration: none;
  color: #000000;
}
.contact-us{
  display: flex;
  flex-direction: row;
}
.contact-us-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-us-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-us-left img{
  width: 80%;
}
.contact-form{
  width: 80%;
  padding: 50px;
  background-color: white;
  border-radius: 20px;
}
.contact-form h2{
  font-size: 40px;
  font-weight: 600;
  font-family: "Playfair Display", serif;

}
.contact-form label{
  font-size: 20px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.contact-form input{
  height: 50px;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: #FEF7F4;
  padding-left: 15px;
}
.contact-form textarea{
  height: 150px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 15px;
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: #FEF7F4;
  padding-left: 15px;
}
.contact-form button{
  width: 100%;
  background-color: #FB7712;
  color: white;
  border-radius: 5px;
  height: 50px;
  border: none;
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;

}
@media(max-width:1200px){
  .contact-form{
    width: 80%;
  }
}
@media(max-width:1100px){
  .contact-us{
    flex-direction: column-reverse;
    gap: 30px;
  }
  .contact-us-left , .contact-us-right{
    width: 100%;
  }
  .contact-form{
    width: 100%;
    padding: 30px;
  }
  .contact-form h2{
    font-size: 30px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
  
  }
}
.tnc{
  padding-left: 150px;
  padding-right: 150px;
}
.tnc h2{
  font-size: 40px;
    font-weight: 600;
    font-family: "Playfair Display", serif;
}
.tnc h3{
  font-size: 25px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  margin-bottom: 20px;
}
.tnc p{
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;

}
.tnc ul li{
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.tnc ul{
  margin-bottom: 20px;

}
@media(max-width:768px){
  .tnc{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 50px;
  }
  .tnc h2{
    font-size: 30px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
  }
}
.search-bar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 50px;
  gap: 20px;
}
.coll{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* gap: 120px; */

}
.col-card{
  height: 150px;
  /* border: 1px solid black; */
  border-radius: 10px;
  width: 31%;
/* background-image: url(./Assets/l1.jpeg); */
background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 10px 40px 0px #00000036;

}
.col-card i{
  color: #f4ded2;
  font-size: 35px;

}
.coll{
  padding: 50px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.col-card p{
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
  text-align: left;


}
.overlay{
  padding: 30px 20px;
height: 100%;
text-align: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  border-radius: 10px;
}
/* Add to your CSS file */
.collections-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  width: 100%;

}

.collection-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  gap: 20px;
}
.collection-card p{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}
.collection-card h4{
  font-size: 20px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
}
.collection-card img{
  border-radius: 10px;
}
.search-bar h2{
  font-size: 40px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
}
.search-bar input{
  height: 100%;
  width: 100%;
  padding-left: 15px;
  border-radius: 15px;
  border: none;

}
.search-bar-div{
  width: 50%;
  border: 1px solid black;
  border-radius: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.bar-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 50px;
}
.bar-heading p{
  font-size: 20px;
      font-weight: 600;
      margin-bottom: 0;
      font-family: "Playfair Display", serif;
}
.bar-heading button{
  height: 40px;
  width: 120px;
  border-radius: 30px;
  border: none;
  background: #fb7712;
  color: white;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;

}
@media(max-width:1030px){
  .coll{
gap: 30px;
  }
  .col-card{
    width: 30%;
  }
}
@media(max-width:900px){
  .coll{
gap: 30px;
padding: 20px;
  }
.bar-heading{
  padding: 0px 20px;

}
  .col-card{
    width: 30%;
  }
  .col-card i{
    color: #f4ded2;
    font-size: 30px;
  
  }
  .col-card p{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 15px;
  
  }
}
@media(max-width:768px){
.col-card{
  width: 45%;
  height: 70px !important;
}
.coll{
  gap: 20px;
}
.search-bar{
  padding: 30px 20px;

}
.search-bar h2{
  font-size: 30px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
}
.search-bar-div{
  width: 80%;
  height: 50px;

}
.bar-heading p{
  font-size: 15px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
}

.bar-heading button{
  height: 30px;
  width: 80px;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
}

@media(max-width:500px){
  .col-card{
  /* padding: 15px; */
  height: 60px;
  width: 47%;

  }
  .col-card i{
    color: #f4ded2;
    font-size: 20px;
  
  }
  .col-card p{
    color: white;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 12px;
  
  }
}
.login-page{
  padding: 20px 50px;
  display: flex;
  flex-direction: row;
  background-image: url(./Assets/image121.png);
  background-size: cover;

}
.login-page-left{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page-left img{
  width: 100%;
}
.login-page-right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-page-right-form{
  background-color: #fdf7f4;
  padding: 50px;
  border-radius: 20px;
  width: 80%;
  display: flex;
  flex-direction: column;
  /* background-image: url(http://localhost:3000/static/media/image121.868a0b4….png); */
  background-image: url(./Assets/image121.png);


}
.login-page-right-form h3{
  font-family: "Playfair Display", serif;
  font-size: 40px;
  font-weight: 700;
  color: #FB7712;
}
.login-page-right-form label{
  font-family: "Montserrat", sans-serif;
font-size: 18px;
font-weight: 500;

}
.login-page-right-form input{
  margin-top: 15px;
  height: 50px;
  padding-left: 15px;
  border-radius: 30px;
  border: 1px solid #FB7712;
}
.login-page-right-form button{
  background-color: #FB7712;
  color: white;
  border-radius: 30px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
height: 50px;

}
.tabs{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}
/* Add this CSS to your stylesheet */
.tab-btn {
  padding: 10px 14px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0; /* Default background color */
  color: #333;
  /* margin-right: 5px; */
  transition: background-color 0.3s ease, color 0.3s ease;
  border: none;
  border-radius: 15px;
  
}

.tab-btn.active {
  background-color: #FB7712 !important; /* Change this to your desired active background color */
  color: white;
  font-weight: bold;
  border: none;

}
.tab-content{
  overflow-x: auto;
  margin-top: 10px;
}
@media(max-width:1000px){
  .login-page-right-form{
    width: 100%;
  }
}
@media(max-width:800px){
  .login-page{
    flex-direction: column-reverse;
  }
  .login-page-left ,.login-page-right{
    width: 100%;
  }
}
@media(max-width:768px){
  .login-page{
    padding: 30px 20px;
}
.login-page-left img{
  width: 80%;
}
.seeall-btn{
  background: #fb7712;
  border: none;
  color: white ;
  font-size: 12px;
  font-weight: 600;
  border-radius: 30px;
  /* height: 48px; */
  padding: 8px 20px;

}
}

@media(max-width:500px){
  .login-page-right-form{
    padding: 20px;
  }
.login-page-right-form h3{
  font-size: 30px;

}


}

.modal-div{
  display: flex;
  flex-direction: column;


}
.modal-div label{
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.modal-div input , .modal-div select{
  margin-bottom: 15px;
  height: 40px;
  padding-left: 15px;
  border-radius: 30px;
  border: 1px solid #FB7712;
  padding-right: 15px;

}
.modal-div button{
  height: 50px;
  background-color: #FB7712;
  color: white;
  border-radius: 30px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;

}
.basic-selector{
  border: none;
  color:#717171 ;
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;

}
.tab-st{
  display: flex;
  flex-direction: row;
}
.tab-left-side{
  width: 60%;
}

.tab-right-side{
  width: 40%;
  padding: 30px;
  padding-top: 0;
}
.main-des-img{
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  /* align-items: center; */
  /* padding: 50px; */
  padding: 0px 50px;
  padding-bottom: 50px;
  gap: 20px;
  height: 80vh;
  display: flex
;
    margin: 16px 0 0;
    height: 80vh;
    max-height: 1024px;
    min-height: 187px;
    width: 100%;
    max-width: 1600px;
    position: relative;
}
.main-des-img-left{
  width: 40% !important;
  display: flex;
  flex-direction: column;
      flex: 1 1;
      position: relative;
      margin-bottom: 6px;
  gap: 20px;
}
.main-des-img-right{
  width: 70%;
  display: flex;
      flex: 2 1;
      position: relative;
      margin-bottom: 6px;
}
.main-des-img-right img{
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 100% !important;
  width: 100% !important;
  position: absolute


}
.main-des-img-left img{
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  height: 48% !important;
  width: 100% !important;


}
.col-banner{
  background-size: cover;
  padding-top: 50px;
  padding-bottom: 100px;
  background-position: bottom;
  background-image: url(./Assets/31098.jpg);
}
.search-bar-div{
  background-color: white;
}
.search-bar2 h2{
  color: white;
}
.path{
  padding:  20px 50px;
  padding-bottom: 0;
}
.path p{
  font-size: 13px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.path p span{
  color: #FB7712;
  font-weight: 500;

}
@media(max-width:960px){
.tab-st{
  flex-direction: column-reverse;
}
.tab-left-side{
  width: 100%;
}
.tab-right-side{
  width: 100%;
  padding: 0 15px;
}
.tab-container{
  padding: 0px 20px;
}
.Collection-detail{
  width: 100%;
}
}
.btn-col{
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.collection-rad{
  background-color: white;
  padding-top: 50px;
  margin-top: -80px;
  border-radius: 70px 70px 0 0;
}
.no-lines-table {
  border-collapse: collapse; 
  width: 100%; 
overflow-x: auto !important;
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.no-lines-table td {
  padding: 5px; 
  border: none; 
  
}
.shad{
  overflow-x: auto !important;
margin-top: 15px;

}
.whatsaps{
  display: none;
  flex-direction: column-reverse;
  align-items: start;
  gap: 20px;
  padding: 20px;
  position: fixed;
  z-index: 100;
  bottom: 0;
  right: 0;
  
}
.whatsaps img{
  width: 50px;
}

@media(max-width:768px){
.whatsaps{
  display: flex;
}
  .col-banner{
    padding-top: 0px;
  }
  .Collection-detail button{
    display: none;

  }
  .collection-rad{
    margin-top: -100px;
    border-radius: 50px 50px 0 0;
  padding-top: 20px;

  }
}
/* .type-button-div
.type-button{
  background-color: #fb7712;
  border-radius: 15px;
  width: 200px;
} */
.veg{
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 15px;
}
 .veg button{
  padding: 10px 10px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat", sans-serif;

 }
 .veg button img{
  height: 20px;
 }


 .swiper {
  /* width: 100%;
  height: 100%;*/
  z-index: 1 !important; 
}

.swiper-slide {
  /* text-align: center; */
  /* font-size: 18px;
  background: #fff; */

  /* Center slide text vertically */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  z-index: 1 !important; 

}

.swiper-slide img {
  /* display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  z-index: 1 !important; 

}

.mySwiper2{
  height: 210px;
  padding: 10px;
  border-radius: 20px;
  position: relative;

}
.mySwiper2 img{
  width: 100%;
  /* height: 220px; */

  

}
.detail-slid{
  display: none;
  padding:0px 15px;
  border-radius: 20px;
}
@media(max-width:768px){

  .newslid{
    /* width: 100%; */
    /* height: 300px; */
    z-index: 0;
  }
  .main-des-img{
    display: none;
  }
  .detail-slid{
    display: flex;
  }
  .path{
    padding: 20px;
    padding-bottom: 0;
  }
  .description h2{
    font-size: 20px;
  }
}



@media(max-width:500px){
  .explore-heading {
    width: 100%;
  margin-top: -60px;

  }
  .explore {
  border-radius: 30px 30px 0 0;

  }

  nav img{
    height: 60px;
  }
}
@media(max-width:400px){
  .Explore-card {
    height: 60px;
    width: 60px;
  }
  .Explore-card img{
    height: 25px;
  }
.Explore-cards {
  gap: 20px;
  justify-content: space-evenly;
}
.Explore-card-div p{
  font-size: 10px;
}

}
.trending-cards3{
  padding: 20px;
  padding-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: white;
  margin-top: -50px;
  border-radius: 50px 50px 0 0;
}
.trending-card2{
  width: 300px;
  height: 350px;
}
.az{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.col-banner2{
  padding-bottom: 50px;
}
@media(max-width:550px){
.trending-card2{
  width: 90%;
  margin-bottom: 120px;
}
}
@media(max-width:500px){
  .trending-card2{
    width: 90%;
    margin-bottom: 50px;
  }
  .az{
    gap: 40px;
  }
  }
@media(max-width:450px){
  .trending-card2{
    width: 90%;
    margin-bottom: 30px;
  }
  .az{
    gap: 20px;
  }
}
@media(max-width:400px){
  .trending-card2{
    width: 90%;
    margin-bottom: 10px;
  }
  .az{
    gap: 20px;
  }
}
@media(max-width:370px){
  .trending-card2{
    width: 90%;
    margin-bottom: 0px;
  }
  .az{
    gap: 40px;
  }
}
@media(max-width:350px){
  .az{
    gap: 30px;
  }
}
div.propertyPolicies ol , div.highLight ol , div.space-di ol{
  padding-left: 0;

}
div.highLight ol li::before {
  content: url('./Assets/Group.png');  /* Path to your image */
  display: inline-block; /* Align the image and text */
  margin-right: 1px; /* Space between image and text */
  vertical-align: middle; /* Align the image with the text */
  list-style: none;
}
.propertyPolicies{

}
div.propertyPolicies ol li::before {
  content: url('./Assets/Group.png');  /* Path to your image */
  display: inline-block; /* Align the image and text */
  margin-right: 1px; /* Space between image and text */
  vertical-align: middle; /* Align the image with the text */
  list-style: none;
}
.share2{
  position: absolute;
  background-color: rgba(255, 255, 255, 0.561);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* margin-left: 10px; */
  margin-top: 10px;
  right: 0;
  margin-right: 30px;
  z-index: 100 !important;
}
.share{
  position: absolute;
  background-color: rgb(255, 255, 255);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 10px;
  z-index: 100 !important;

}
.share button{
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

}
.share i{
  font-size: 15px;
  margin-top: -3px;
  margin-left: -3px;
  z-index: 100;

}
.share2 i{
  font-size: 15px;
  margin-top: -3px;
  margin-left: -3px;
  z-index: 100;

}
.share2 button{
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: red;

}
.allimg{
  display: flex;
  flex-direction: column;
  z-index: 100;


}
.allimg img{
  width: 100%;
  
}
.all-img-btn{
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  margin-left: 20px;
  /* height: 35px; */
  padding: 10px 20px;
  background-color: #0000006e;
  border-radius: 10px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid white;
  z-index: 100;

}

ol{
  padding-left: 0;
}
.youtube-video{
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  margin-bottom: 10px;
  z-index: 100;
}
.youtube-video i{
  font-size: 40px;
  color: rgba(255, 255, 255, 0.561);
  z-index: 100;

}
.coll2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 40px;
  
}
.col-card2{
  height: 250px;
  text-align: center !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.col-card p{
  text-align: center !important;

}
.overlay2{
  text-align: center;
  
}

@media(max-width:380px){

  .col-card{
    width: 47%;
  }
  .coll2{
    justify-content: space-between;
    gap: 20px;
  }
}
.pool-size{
display: flex;
flex-direction: row;
justify-content: start;
align-items: center;
gap: 10px;
margin-top: 5px;
}
.pool-size h6{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 11px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  color: #737373;
}
.pool-size h6 img{
  height: 20px;

}
@media(max-width:600px){
  .count-card img{
    height: 40px;

  }
  .count-card h2 {
    font-size: 20px;
  }
  .count-card p {
    font-size: 12px;
}
.count{
  gap: 20px;
  justify-content: space-between;

}
.coll2{
  justify-content: space-between;
  gap: 20px;
}
}

@media(max-width:600px){
  .coll2 , .coll{
    justify-content: space-between;
    gap: 12px;
  }
}
.ExploreTripolo-heading{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.Explore-cards2{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 40px;
}
.active{
  background-color: #fb7712;
  /* border-bottom: 3px solid #fb7712; */
  border-radius: 30px;
  color: white;
}
.loader-container{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  background-color: white;
}
.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.loader:before {
  animation: ball1 1s infinite;
  background-color: #f5c4b0;
  box-shadow: 30px 0 0 #fb7712;
  margin-bottom: 10px;
}
.loader:after {
  animation: ball2 1s infinite;
  background-color: #fb7712;
  box-shadow: 30px 0 0 #f5c4b0;
}

@keyframes rotate {
  0% { transform: rotate(0deg) scale(0.8) }
  50% { transform: rotate(360deg) scale(1.2) }
  100% { transform: rotate(720deg) scale(0.8) }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #fb7712;
  }
  50% {
    box-shadow: 0 0 0 #fb7712;
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fb7712;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fef7f4;
  }
  50% {
    box-shadow: 0 0 0 #fef7f4;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #fef7f4;
    margin-top: 0;
  }
}

.loader-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media(max-width:550px){
  .youtube-video i{
    font-size: 30px;

}
.share2{
  height: 30px;
  width: 30px;
}
.all-img-btn{
  font-size: 12px;
  padding: 8px 15px;

}
}
.navlogo{
  height: 55px;

}
.assasa{
  display: flex;
  flex-direction: row;
  align-items: center;
  
}
.assasa p{
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #fb7712;
  width: 100px;
  margin-left: 0px;
  

}
.submit-bnts{
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  color: white;
  background-color: #fb7712;
}
.custom-list {
  list-style: none; /* Remove default bullets */
  padding-left: 20px; /* Add some padding for alignment */
}

.custom-list li {
  position: relative;
  padding-left: 15px; /* Space for the custom bullet */
}

.custom-list li::before {
  content: "•"; /* Unicode for a bullet */
  position: absolute;
  left: 0;
  top: 0;
  color: black; /* Customize the bullet color */
  font-size: 1.2em; /* Adjust bullet size */
  line-height: 1; /* Align bullet with text */
}
