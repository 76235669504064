/* Popup dialog styling */
.popup-dialog {
    display: none;
    position: fixed; /* Center in the viewport */
    top: 50%; /* Vertically centered */
    left: 50%; /* Horizontally centered */
    transform: translate(-50%, -50%); /* Adjust for centering */
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    z-index: 1000; /* Ensure it’s on top */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
    text-align: center;
    width: 500px; /* Larger width */
    height: 500px; /* Larger height */
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Larger image inside the popup */
  .popup-dialog img {
    width: 100%; /* Fit within the dialog */
    height: 100%; /* Fit within the dialog */
    border-radius: 8px;
  }
  
  /* Hover to show the dialog */
  .meal-container:hover .popup-dialog {
    display: block;
  }
  
  /* Optional fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translate(-50%, -55%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
  